import React, { useRef, useState } from "react";
import Table from "react-bootstrap/Table";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { Button, Card, Spinner } from "react-bootstrap";
import "./test.css"; // Custom CSS file
import { FaFileAlt } from "react-icons/fa";
import { FaFolderOpen } from "react-icons/fa6";
import axios from "axios";
import config from "../../config/config";
import { apiHeaderToken, apiHeaderTokenMultiPart } from "../../config/api_header";
import { toast } from "react-toastify";
import { CircularProgressbar } from "react-circular-progressbar";

const tabStyle = {
    width: "500px", // Set the desired width for all tabs
    // height: '50px',    // Set the desired height for all tabs
    textAlign: "center",
    // padding: '10px',
    fontWeight: "bold",
    color: "#000",
    backgroundColor: "#f1f1f1", // Light gray background for unselected tab
    borderRadius: "5px", // Slight border-radius
    // margin: '0 5px', // Margin between tabs
};

const activeTabStyle = {
    ...tabStyle,
    // backgroundColor: 'green',
    color: "white",
};

const cardStyle = {
    width: "200px", // Width of each card
    textAlign: "center", // Center the text inside the card
    border: "1px solid #007bff", // Optional: add border to card
    borderRadius: "5px", // Slight border-radius for the card
};

export default function Emp_document({ employeeDoc, getEmployeeListFun }) {
    const [activeTab, setActiveTab] = useState("KYC");

    // Filter documents by category based on the active tab

    console.log(employeeDoc, 'this is Employee Docs here.....................')
    const filteredDocs = employeeDoc?.docs?.filter(
        (doc) => doc.doc_category === activeTab
    );

    const [loading, setLoading] = useState({
        label: '',
        status: false,
    });

    const fileInputRefs = {
        adharBack: useRef(null),
        adharFront: useRef(null),
        panCard: useRef(null),
        otherKycDocument: useRef(null),
        markSheet10: useRef(null),
        markSheet12: useRef(null),
        graduationDegree: useRef(null),
        otherEducationDocument: useRef(null),
        relievingLetter: useRef(null),
        experienceCertificate: useRef(null),
        finalSettlementCertificate: useRef(null),
        otherExperienceDocument: useRef(null),
    };

    const [selectedFiles, setSelectedFiles] = useState({
        adharBack: null,
        adharFront: null,
        panCard: null,
        otherKycDocument: null,
        markSheet10: null,
        markSheet12: null,
        graduationDegree: null,
        otherEducationDocument: null,
        relievingLetter: null,
        experienceCertificate: null,
        finalSettlementCertificate: null,
        otherExperienceDocument: null,
    });

    const handleButtonClick = (type) => {
        fileInputRefs[type].current.click();
    };

    const handleFileChange = async (event, type, sub_doc_category, name) => {
        const files = event.target.files[0];

        console.log(files, 'this is File name here mkc')
        setSelectedFiles((prevState) => ({
            ...prevState,
            [type]: files,
        }));
        await handleUpload(type, files, sub_doc_category, name);
    };

    /******************** Handle Uploads File With the Multiple ************/

    const handleUpload = async (type, files, sub_doc_category, name) => {
        const formData = new FormData();

        formData.append("filename", files);
        formData.append("_id", employeeDoc?._id);
        formData.append("doc_category", type);
        formData.append("sub_doc_category", sub_doc_category);
        formData.append("doc_name", name);

        try {
            setLoading({
                label: name,
                status: true
            });
            let response = await axios.post(`${config.API_URL}uploadEmployeeKycDocs`, formData, apiHeaderTokenMultiPart(config.API_TOKEN))
            if (response.status === 200) {
                toast.success(response.data?.message)
                getEmployeeListFun();
            } else {
                toast.error(response.data?.message)
            }
            setLoading({
                label: '',
                status: false
            });

        } catch (error) {
            console.error(`Upload failed for ${type}`, error);
            setLoading({
                label: '',
                status: false
            });
        }
    };

    return (
        <>
            <div className="row my-3" data-aos="fade-in" data-aos-duration="3000">
                <div className="col-lg-12">
                    <div className="sitecard pr-0 h-100 ps-0 pt-4">
                        <div className="d-flex flex-column justify-content-around ">
                            {/* Tabs for document categories */}
                            <Tabs
                                activeKey={activeTab}
                                onSelect={(tab) => setActiveTab(tab)}
                                className="mb-3"
                            >
                                <Tab
                                    eventKey="KYC"
                                    title="KYC Documents"
                                    style={activeTab === "KYC" ? activeTabStyle : tabStyle}
                                />
                                <Tab
                                    eventKey="Educational"
                                    title="Educational Documents"
                                    style={
                                        activeTab === "Educational" ? activeTabStyle : tabStyle
                                    }
                                />
                                <Tab
                                    eventKey="Experience"
                                    title="Experience Documents"
                                    style={activeTab === "Experience" ? activeTabStyle : tabStyle}
                                />
                            </Tabs>

                            {/* Tab Panels */}
                            <div className="tab-content">
                                {activeTab === "KYC" && (
                                    <div className="d-flex justify-content-around flex-wrap">
                                        <Card className="cart-style">
                                            <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                                                <FaFolderOpen
                                                    size={48}
                                                    style={{ color: "green", marginBottom: "10px" }}
                                                />
                                                <Card.Title className="text-center">
                                                    Upload Aadhaar Back
                                                </Card.Title>
                                                {/* <Button
                                                    onClick={() => handleButtonClick("adharBack")}
                                                    variant="primary"
                                                    className="mt-3"
                                                    disabled={loading} // Disable the button if loading
                                                    startIcon={loading ? <CircularProgressbar size={20} color="inherit" /> : null}
                                    
                                                >
                                                    Choose File
                                                </Button> */}
                                                <Button
                                                    onClick={() => handleButtonClick("adharBack")}
                                                    variant="primary"
                                                    className="mt-3"
                                                    disabled={loading.label === 'Aadhar Back' && loading.status}
                                                >
                                                    {loading.label === 'Aadhar Back' && loading.status ? (
                                                        <>
                                                            <Spinner animation="border" size="sm" /> Uploading...
                                                        </>
                                                    ) : (
                                                        "Choose File"
                                                    )}
                                                </Button>

                                                <input
                                                    type="file"
                                                    ref={fileInputRefs.adharBack}
                                                    style={{ display: "none" }}
                                                    accept=".jpg,.jpeg,.png"
                                                    onChange={(e) =>
                                                        handleFileChange(
                                                            e,
                                                            "KYC",
                                                            "Document",
                                                            "Aadhar Back"
                                                        )
                                                    }
                                                />
                                            </Card.Body>
                                        </Card>
                                        <Card className="cart-style">
                                            <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                                                <FaFolderOpen
                                                    size={48}
                                                    style={{ color: "green", marginBottom: "10px" }}
                                                />
                                                <Card.Title className="text-center">
                                                    Upload Aadhaar Front
                                                </Card.Title>
                                                <Button
                                                    onClick={() => handleButtonClick("adharFront")}
                                                    variant="primary"
                                                    className="mt-3"
                                                    disabled={loading.label === 'Aadhar Front' && loading.status}
                                                >
                                                    {loading.label === 'Aadhar Front' && loading.status ? (
                                                        <>
                                                            <Spinner animation="border" size="sm" /> Uploading...
                                                        </>
                                                    ) : (
                                                        "Choose File"
                                                    )}
                                                </Button>
                                                <input
                                                    type="file"
                                                    ref={fileInputRefs.adharFront}
                                                    style={{ display: "none" }}
                                                    accept=".jpg,.jpeg,.png"
                                                    onChange={(e) => handleFileChange(
                                                        e,
                                                        "KYC",
                                                        "Document",
                                                        "Aadhar Front"
                                                    )}
                                                />
                                            </Card.Body>
                                        </Card>
                                        <Card className="cart-style">
                                            <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                                                <FaFolderOpen
                                                    size={48}
                                                    style={{ color: "green", marginBottom: "10px" }}
                                                />
                                                <Card.Title className="text-center">
                                                    Upload PAN Card
                                                </Card.Title>

                                                <Button
                                                    onClick={() => handleButtonClick("panCard")}
                                                    variant="primary"
                                                    className="mt-3"
                                                    disabled={loading.label === 'Pancard' && loading.status}
                                                >
                                                    {loading.label === 'Pancard' && loading.status ? (
                                                        <>
                                                            <Spinner animation="border" size="sm" /> Uploading...
                                                        </>
                                                    ) : (
                                                        "Choose File"
                                                    )}
                                                </Button>
                                                <input
                                                    type="file"
                                                    ref={fileInputRefs.panCard}
                                                    style={{ display: "none" }}
                                                    accept=".jpg,.jpeg,.png"
                                                    onChange={(e) => handleFileChange(e, 'KYC', 'Document', 'Pancard')}
                                                />
                                            </Card.Body>
                                        </Card>
                                        <Card className="cart-style">
                                            <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                                                <FaFolderOpen
                                                    size={48}
                                                    style={{ color: "green", marginBottom: "10px" }}
                                                />
                                                <Card.Title className="text-center">
                                                    Upload Other Documents
                                                </Card.Title>
                                                {/* <Button
                                                    onClick={() => handleButtonClick("otherKycDocument")}
                                                    variant="primary"
                                                    className="mt-3"
                                                >
                                                    Choose File
                                                </Button> */}
                                                <Button
                                                    onClick={() => handleButtonClick("otherKycDocument")}
                                                    variant="primary"
                                                    className="mt-3"
                                                    disabled={loading.label === 'otherKycDocument' && loading.status}
                                                >
                                                    {loading.label === 'otherKycDocument' && loading.status ? (
                                                        <>
                                                            <Spinner animation="border" size="sm" /> Uploading...
                                                        </>
                                                    ) : (
                                                        "Choose File"
                                                    )}
                                                </Button>
                                                <input
                                                    type="file"
                                                    ref={fileInputRefs.otherKycDocument}
                                                    style={{ display: "none" }}
                                                    accept=".jpg,.jpeg,.png"
                                                    multiple
                                                    onChange={(e) =>
                                                        handleFileChange(e, 'KYC', 'Document', 'otherKycDocument')
                                                    }
                                                />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                )}

                                {activeTab === "Educational" && (
                                    <div className="d-flex justify-content-around flex-wrap">
                                        <Card className="cart-style">
                                            <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                                                <FaFolderOpen
                                                    size={48}
                                                    style={{ color: "green", marginBottom: "10px" }}
                                                />
                                                <Card.Title className="text-center">
                                                    10th Mark Sheet
                                                </Card.Title>
                                                <input
                                                    type="file"
                                                    ref={fileInputRefs.markSheet10}
                                                    style={{ display: "none" }}
                                                    accept=".jpg,.jpeg,.png"
                                                    onChange={(e) => handleFileChange(e, 'Educational', 'Marksheet', '10th Marksheet')}
                                                />
                                                <Button
                                                    onClick={() => handleButtonClick("markSheet10")}
                                                    variant="primary"
                                                    className="mt-3"
                                                    disabled={loading.label === '10th Marksheet' && loading.status}
                                                >
                                                    {loading.label === '10th Marksheet' && loading.status ? (
                                                        <>
                                                            <Spinner animation="border" size="sm" /> Uploading...
                                                        </>
                                                    ) : (
                                                        "Choose File"
                                                    )}
                                                </Button>
                                            </Card.Body>
                                        </Card>

                                        <Card className="cart-style">
                                            <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                                                <FaFolderOpen
                                                    size={48}
                                                    style={{ color: "green", marginBottom: "10px" }}
                                                />
                                                <Card.Title className="text-center">
                                                    12th Mark Sheet
                                                </Card.Title>
                                                <input
                                                    type="file"
                                                    ref={fileInputRefs.markSheet12}
                                                    style={{ display: "none" }}
                                                    multiple
                                                    accept=".jpg,.jpeg,.png"
                                                    onChange={(e) => handleFileChange(e, 'Educational', 'Marksheet', '12th Marksheet')}
                                                />
                                                <Button
                                                    onClick={() => handleButtonClick("markSheet12")}
                                                    variant="primary"
                                                    className="mt-3"
                                                    disabled={loading.label === '12th Marksheet' && loading.status}
                                                >
                                                    {loading.label === '12th Marksheet' && loading.status ? (
                                                        <>
                                                            <Spinner animation="border" size="sm" /> Uploading...
                                                        </>
                                                    ) : (
                                                        "Choose File"
                                                    )}
                                                </Button>
                                            </Card.Body>
                                        </Card>

                                        <Card className="cart-style">
                                            <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                                                <FaFolderOpen
                                                    size={48}
                                                    style={{ color: "green", marginBottom: "10px" }}
                                                />
                                                <Card.Title className="text-center">
                                                    Graduation Degree
                                                </Card.Title>
                                                <input
                                                    type="file"
                                                    ref={fileInputRefs.graduationDegree}
                                                    style={{ display: "none" }}
                                                    multiple
                                                    accept=".jpg,.jpeg,.png"
                                                    onChange={(e) =>
                                                        handleFileChange(e, 'Educational', 'Marksheet', 'Graduation Degree')
                                                    }
                                                />
                                                {/* <Button
                                                    onClick={() => handleButtonClick("graduationDegree")}
                                                    variant="primary"
                                                    className="mt-3"
                                                >
                                                    Choose File
                                                </Button> */}
                                                <Button
                                                    onClick={() => handleButtonClick("graduationDegree")}
                                                    variant="primary"
                                                    className="mt-3"
                                                    disabled={loading.label === 'Graduation Degree' && loading.status}
                                                >
                                                    {loading.label === 'Graduation Degree' && loading.status ? (
                                                        <>
                                                            <Spinner animation="border" size="sm" /> Uploading...
                                                        </>
                                                    ) : (
                                                        "Choose File"
                                                    )}
                                                </Button>
                                            </Card.Body>
                                        </Card>

                                        <Card className="cart-style">
                                            <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                                                <FaFolderOpen
                                                    size={48}
                                                    style={{ color: "green", marginBottom: "10px" }}
                                                />
                                                <Card.Title className="text-center">
                                                    Post Graduation / Other Document
                                                </Card.Title>
                                                <input
                                                    type="file"
                                                    ref={fileInputRefs.otherEducationDocument}
                                                    style={{ display: "none" }}
                                                    multiple
                                                    accept=".jpg,.jpeg,.png"
                                                    onChange={(e) =>
                                                        handleFileChange(e, 'Educational', 'Marksheet', 'Post Graduation / Other Educational Document')
                                                    }
                                                />
                                                <Button
                                                    onClick={() => handleButtonClick("otherEducationDocument")}
                                                    variant="primary"
                                                    className="mt-3"
                                                    disabled={loading.label === 'Post Graduation / Other Educational Document' && loading.status}
                                                >
                                                    {loading.label === 'Post Graduation / Other Educational Document' && loading.status ? (
                                                        <>
                                                            <Spinner animation="border" size="sm" /> Uploading...
                                                        </>
                                                    ) : (
                                                        "Choose File"
                                                    )}
                                                </Button>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                )}

                                {activeTab === "Experience" && (
                                    <div className="d-flex justify-content-around flex-wrap">
                                        {/* Relieving Letter */}
                                        <Card className="cart-style">
                                            <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                                                <FaFolderOpen
                                                    size={48}
                                                    style={{ color: "green", marginBottom: "10px" }}
                                                />
                                                <Card.Title className="text-center">
                                                    Relieving Letter
                                                </Card.Title>
                                                <Button
                                                    onClick={() => handleButtonClick("relievingLetter")}
                                                    variant="primary"
                                                    className="mt-3"
                                                    disabled={loading.label === 'Relieving Letter' && loading.status}
                                                >
                                                    {loading.label === 'Relieving Letter' && loading.status ? (
                                                        <>
                                                            <Spinner animation="border" size="sm" /> Uploading...
                                                        </>
                                                    ) : (
                                                        "Choose File"
                                                    )}
                                                </Button>
                                                <input
                                                    type="file"
                                                    ref={fileInputRefs.relievingLetter}
                                                    style={{ display: "none" }}
                                                    accept=".jpg,.jpeg,.png"
                                                    multiple
                                                    onChange={(e) =>
                                                        handleFileChange(e, 'Experience', 'Letter', 'Relieving Letter')
                                                    }
                                                />
                                            </Card.Body>
                                        </Card>

                                        {/* Experience Certificate */}
                                        <Card className="cart-style">
                                            <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                                                <FaFolderOpen
                                                    size={48}
                                                    style={{ color: "green", marginBottom: "10px" }}
                                                />
                                                <Card.Title className="text-center">
                                                    Experience Certificate
                                                </Card.Title>
                                                {/* <Button
                                                    onClick={() =>
                                                        handleButtonClick("experienceCertificate")
                                                    }
                                                    variant="primary"
                                                    className="mt-3"
                                                >
                                                    Upload Document
                                                </Button> */}
                                                <Button
                                                    onClick={() => handleButtonClick("experienceCertificate")}
                                                    variant="primary"
                                                    className="mt-3"
                                                    disabled={loading.label === 'Experience Letter' && loading.status}
                                                >
                                                    {loading.label === 'Experience Letter' && loading.status ? (
                                                        <>
                                                            <Spinner animation="border" size="sm" /> Uploading...
                                                        </>
                                                    ) : (
                                                        "Choose File"
                                                    )}
                                                </Button>
                                                <input
                                                    type="file"
                                                    ref={fileInputRefs.experienceCertificate}
                                                    style={{ display: "none" }}
                                                    accept=".jpg,.jpeg,.png"
                                                    multiple
                                                    onChange={(e) =>
                                                        handleFileChange(e, 'Experience', 'Letter', 'Experience Letter')
                                                    }
                                                />
                                            </Card.Body>
                                        </Card>

                                        {/* Final Settlement Certificate */}
                                        <Card className="cart-style">
                                            <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                                                <FaFolderOpen
                                                    size={48}
                                                    style={{ color: "green", marginBottom: "10px" }}
                                                />
                                                <Card.Title className="text-center">
                                                    Final Settlement Certificate
                                                </Card.Title>
                                                <Button
                                                    onClick={() => handleButtonClick("finalSettlementCertificate")}
                                                    variant="primary"
                                                    className="mt-3"
                                                    disabled={loading.label === 'Final Settlement Certificate' && loading.status}
                                                >
                                                    {loading.label === 'Final Settlement Certificate' && loading.status ? (
                                                        <>
                                                            <Spinner animation="border" size="sm" /> Uploading...
                                                        </>
                                                    ) : (
                                                        "Choose File"
                                                    )}
                                                </Button>
                                                <input
                                                    type="file"
                                                    ref={fileInputRefs.finalSettlementCertificate}
                                                    style={{ display: "none" }}
                                                    accept=".jpg,.jpeg,.png"
                                                    multiple
                                                    onChange={(e) =>
                                                        handleFileChange(e, 'Experience', 'Letter', 'Final Settlement Certificate')
                                                    }
                                                />
                                            </Card.Body>
                                        </Card>

                                        {/* Other Document */}
                                        <Card className="cart-style">
                                            <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                                                <FaFolderOpen
                                                    size={48}
                                                    style={{ color: "green", marginBottom: "10px" }}
                                                />
                                                <Card.Title className="text-center">
                                                    Other Document
                                                </Card.Title>
                                                <Button
                                                    onClick={() => handleButtonClick("otherExperienceDocument")}
                                                    variant="primary"
                                                    className="mt-3"
                                                    disabled={loading.label === 'Other Certificate' && loading.status}
                                                >
                                                    {loading.label === 'Other Certificate' && loading.status ? (
                                                        <>
                                                            <Spinner animation="border" size="sm" /> Uploading...
                                                        </>
                                                    ) : (
                                                        "Choose File"
                                                    )}
                                                </Button>
                                                <input
                                                    type="file"
                                                    ref={fileInputRefs.otherExperienceDocument}
                                                    style={{ display: "none" }}
                                                    accept=".jpg,.jpeg,.png"
                                                    multiple
                                                    onChange={(e) =>
                                                        handleFileChange(e, 'Experience', 'Letter', 'Other Certificate')
                                                    }
                                                />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                )}
                            </div>

                            {/* Document Table */}
                            <div className="d-flex flex-column gap-2 mt-1 scroller-content w-100 smalldata infobox">
                                <Table className="candd_table">
                                    <thead>
                                        <tr>
                                            <th className="text-center fw-medium">S.No</th>
                                            <th className="text-center fw-medium">Document Name</th>
                                            <th className="text-center fw-medium">Category</th>
                                            <th className="text-center fw-medium">Attached Date</th>
                                            <th className="text-center fw-medium">View</th>
                                            <th className="text-center fw-medium">Verified By</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredDocs && filteredDocs.length === 0 ? (
                                            <tr>
                                                <td colSpan="6">No documents available</td>
                                            </tr>
                                        ) : (
                                            filteredDocs?.map((doc, index) => (
                                                <tr key={doc.id || index}>
                                                    <td>{index + 1}</td>
                                                    <td>{doc.doc_name || "N/A"}</td>
                                                    <td>{doc.doc_category || "N/A"}</td>
                                                    <td>{new Date(doc.add_date).toLocaleDateString()}</td>
                                                    <td>
                                                        <a href={`${config.IMAGE_PATH}${doc?.file_name}`} target="_blank" rel="noopener noreferrer">
                                                            <img
                                                                src={`${config.IMAGE_PATH}${doc?.file_name}`}
                                                                alt="Document Preview"
                                                                style={{ width: "100px", height: "auto", cursor: "pointer" }}
                                                            />
                                                        </a>
                                                    </td>
                                                    <td>{doc.name || "N/A"}</td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
