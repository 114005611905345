import config from "../config/config";
import React, { useState, useEffect } from "react";
import PeopleKRA from "./PeopleKRA";
import PeopleFNF from "./PeopleFnf";
import ReportingManager from "./Reportingmanager";

import candidate from "../images/candidate.png";
import { FaLinkedin } from "react-icons/fa";
import Table from "react-bootstrap/Table";
import { RiH5, RiTwitterXFill } from "react-icons/ri";
import { GoDownload } from "react-icons/go";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import StepConnector from "@mui/material/StepConnector";
import { styled } from "@mui/system";

import { CiCalendar } from "react-icons/ci";
import GoBackButton from "./Goback";
import Emp_info from "./Profile/Employee-info";
import Emp_salary from "./Profile/Employee-salary";
import EmpAttendance from "./Profile/Employee-attendence";
import Emp_credit_history from "./Profile/Emp-credit-history";
import Emp_document from "./Profile/Emp-documents";
import { getEmployeeById, getCandidateByEmailName } from "./helper/Api_Helper";
import AllHeaders from "../features/partials/AllHeaders";
import DEFAULT_AVATAR from ".././images/avator.png"
import moment from "moment";
import { calculateTenure } from "../utils/common";
import PeopleKPIKRA from "./PeopleKpiKra";
import EmployeeJourney from "./EmployeeJourney";
const IMAGE_PATH = config.IMAGE_PATH;

export default function People() {
  const [formData, setFormData] = useState([]);
  const [employeeDoc, setEmployeeDoc] = useState([]);
  const [employeePhoto, setEmployeePhoto] = useState();

  const fetchCandidateData = async () => {
    try {
      const myId = localStorage.getItem("onBoardingId");
      const response = await getEmployeeById({ _id: myId, scope_fields: [] });
      const data = response.data;
      setFormData(data);
    } catch (error) {
      console.error("Error fetching candidate data", error);
    }
  };

  useEffect(() => {
    fetchCandidateData();
  }, []); 

  useEffect(() => {
    if (formData.email) {
      // Check if formData.email is available
      const fetchEmployeeDoc = async () => {
        try {
          const response = await getCandidateByEmailName(formData.email);
          setEmployeePhoto(response.data.photo);
          if (response && response.data && Array.isArray(response.data.docs)) {
            setEmployeeDoc(response.data.docs);
          } else {
            setEmployeeDoc([]);
          }
        } catch (error) {
          console.error("Error fetching documents:", error);
          setEmployeeDoc([]);
        }
      };

      fetchEmployeeDoc();
    }
  }, [formData.email]);

  const permanentAddressData =
    formData.permanent_address && typeof formData.permanent_address === "object"
      ? formData.permanent_address
      : {};

  const imagePath = employeePhoto ? IMAGE_PATH + employeePhoto : DEFAULT_AVATAR;

  return (
    <>
      <AllHeaders />
      <div className="maincontent">
        <div
          className="container ps-5"
          data-aos="fade-in"
          data-aos-duration="3000"
        >
          <GoBackButton />
          <div className="sitecard">
            <div className="cd_profilebox d-flex">
              <div className="cd_prfleft">
                <img src={imagePath} />
                <div className="dfx">
                  <h4 className="name">{formData.name}</h4>
                  <p className="empcode">
                    Employee Code: {formData.employee_code}
                  </p>
                  <p className="empcode">
                    Designation : {formData?.designation}
                  </p>
                  <p className="empcode">
                    Project : {formData?.project_name}
                  </p>
                  <p className="empcode">
                    Batch Id : {formData?.batch_id ? formData?.batch_id : 'N/A' }
                  </p>
                </div>
              </div>
              <div className="cd_prfright d-flex">
                <div className="cnt_info">
                  <h6>Contact Information</h6>
                  <p>+91-{formData.mobile_no}</p>
                  <p>{formData.email}</p>
                  <p>
                    {permanentAddressData.city_district},{" "}{permanentAddressData.state_name},{" "}
                    {permanentAddressData.pin_code}
                  </p>
                  <ul className="social">
                    <li>
                      <a href="#">
                        <FaLinkedin />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <RiTwitterXFill />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <FaFacebook />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <FaInstagram />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="cnt_info prev_empinfo">
                  <h6>Employment Info</h6>
                  <p>Joining Date : { formData.joining_date }</p>
                  <p>Appraisal Date : {moment(formData.appraisal_date).utcOffset('+05:30').format('MMMM D , YYYY') ? moment(formData.appraisal_date).utcOffset('+05:30').format('MMMM D , YYYY'): 'N/A'}</p>
                  <p>DOB : {moment(formData.date_of_birth).utcOffset('+05:30').format('MMMM D , YYYY') ? moment(formData.date_of_birth).utcOffset('+05:30').format('MMMM D , YYYY') : 'N/A'}</p>
                  <p>Contract End Date : {moment(formData.valid_till, "MMMM D, YYYY, h:mm A").format('MMMM D , YYYY') !== 'Invalid date' ? moment(formData.valid_till, "MMMM D, YYYY, h:mm A").format('MMMM D , YYYY') : 'N/A'}</p>
                  <p>Tenure : {calculateTenure(formData.joining_date) ? calculateTenure(formData.joining_date) : 'N/A'}</p>
                </div>
              </div>
            </div>
          </div>
          <Tab.Container
            id="left-tabs-example"
            className="mt-3"
            defaultActiveKey="first"
          >
            <Nav
              variant="pills"
              className="flex-row postedjobs widthcomp border-full peoplstabss"
            >
              <Nav.Item>
                <Nav.Link eventKey="first">Employee Info</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">Salary</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="third">Attendance</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="four">KPI / KRA</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="six">JD</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="seven">Credit History</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="eight">Documents</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="nine">FnF</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="ten">Add Manager</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="five">Employee Journey</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <Emp_info data={formData} />
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <Emp_salary data={formData} />
              </Tab.Pane>
              <Tab.Pane eventKey="third">
                <EmpAttendance data={formData} />
              </Tab.Pane>
              <Tab.Pane eventKey="seven">
                <Emp_credit_history />
              </Tab.Pane>
              <Tab.Pane eventKey="eight">
                <Emp_document employeeDoc={formData} getEmployeeListFun={fetchCandidateData}/>
              </Tab.Pane>
              <Tab.Pane eventKey="four">
                <PeopleKPIKRA data={formData} getEmployeeListFun={fetchCandidateData} />
              </Tab.Pane>
              <Tab.Pane eventKey="six">
                <PeopleKRA TabType={"jd"} userData={formData} />
              </Tab.Pane>
              <Tab.Pane eventKey="nine">
                <PeopleFNF userData={formData} />
              </Tab.Pane>
              <Tab.Pane eventKey="ten">
                <ReportingManager userData={formData} getEmployeeListFun={fetchCandidateData} />
              </Tab.Pane>
              <Tab.Pane eventKey="five">
                <EmployeeJourney userData={formData}  />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    </>
  );
}
