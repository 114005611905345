import React, { useState } from "react";
import { useEffect } from "react";
import config from "../../config/config";
import logo from '../../images/logo.png'
// import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Form from 'react-bootstrap/Form';
// import Button from 'react-bootstrap/Button';
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import axios from "axios";
import { apiHeaderToken } from "../../config/api_header";
import { toast } from "react-toastify";




// Helper function to convert URL-safe base64 to standard base64
const urlSafeBase64Decode = (base64String) => {

    let base64 = base64String.replace(/-/g, "+").replace(/_/g, "/");
    while (base64.length % 4 !== 0) {
        base64 += "=";
    }

    return atob(base64);
};


const MprFormValidation = () => {
    // get the mpr form Validation
    const [id, setId] = useState("");
    const [utm, setUtm] = useState("");
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [text, setText] = useState('')


    const [value, setValue] = useState("Approved");

    const handleChange = (e) => {
        setValue(e.target.value);
    };

    useEffect(() => {
        const url = new URL(`${config.API_URL}mprFrm/NjZmYTlkZmJkOWNiMmViNjMxNjNiODU4?utm=Q0VP`);

        // alert(url);
        const urlFirstParts = url.pathname.split("/");
        const mprFrmIndex = urlFirstParts.indexOf("mprFrm");
        const mprDocId = urlFirstParts[mprFrmIndex + 1];
        const utmValue = url.searchParams.get("utm");
        try {
            const decodedId = atob(mprDocId);
            const decodedUtm = atob(utmValue);

            // Set the state
            setId(decodedId);
            setUtm(decodedUtm);
        } catch (error) {
            console.error("Error decoding Base64 string:", error);
        }
    }, []);

    //   Fetch the records of using Id ->   
    useEffect(() => {
        if (id) {
            (async () => {
                try {
                    let Payloads = {
                        "_id": id,
                    }
                    setLoading(true)
                    let response = await axios.post(`${config.API_URL}getRequisitionDataByIdViaMail`, Payloads, apiHeaderToken(config.API_TOKEN));
                    if (response.status === 200) {
                        setData(response.data?.data);
                    }
                    setLoading(false)
                } catch (error) {
                    console.error("Error fetching data:", error);
                    setLoading(false)
                }
            })()
        }
    }, [id])

    //  Handle Submit the FeedBack ->>>>>>>>>>>
    const handleSubmitFeedBack = (e) => {
        e.preventDefault();
        if (!value) {
            return toast.warn("Please Select the Status");
        }
        if (!text) {
            return toast.warn("Please Add Comment")
        }

        let Payloads = {
            "_id": id,
            "designation": utm,
            "status": value,
            "comment": text
        }
        axios.post(`${config.API_URL}approveRejectRequisitionFormViaMail`, Payloads)
            .then((response) => {
                console.log(response, 'this is response Data from the server there are some issue related the data');
                if (response.status === 200) {
                    toast.success("FeedBack Sent Successfully")
                    setText("")
                    setValue("")
                } else {
                    toast.error(response.data.data?.message)
                }
            })
            .catch((err) => {
                toast.error(err.response.data.message)
            })
    }

    console.log(id, 'docorded Data from the');
    console.log(utm, 'docorded Data from the');
    console.log(data, 'This is Data');


    return (
        <>
            <div className="container">
                <div className="mprdwrapper">
                    <div className="mprdatas statuscard">
                        <div className="mprlogo">
                            <img src={logo} alt="logo" />
                        </div>
                        {
                            loading ?
                                <div className="d-flex align-content-center justify-content-center">
                                    <div className="spinner-border text-primary" role="status" />
                                </div> :
                                !data && data?.length < 0 ?
                                    <div className="d-flex align-content-center justify-content-center">
                                        <p>No Data Found</p>
                                    </div> :
                                    <div className="mprtext">
                                        <h4>Manpower Requisition Data </h4>
                                        <div className="manpwr_data_row row">
                                            <div className="col-sm-4 manpwr_data_colm">
                                                <h6>Vacancy Under Project</h6>
                                                <p>{data?.project_name}</p>
                                            </div>
                                            <div className="col-sm-4 manpwr_data_colm">
                                                <h6>Designation</h6>
                                                <p>{data?.designation_name}</p>
                                            </div>
                                            <div className="col-sm-4 manpwr_data_colm">
                                                <h6>Department</h6>
                                                <p>{data?.department_name}</p>
                                            </div>
                                            <div className="col-sm-4 manpwr_data_colm">
                                                <h6>Type of Opening</h6>
                                                <p>{data?.type_of_opening}</p>
                                            </div>
                                            <div className="col-sm-4 manpwr_data_colm">
                                                <h6>CTC Proposed per Annum</h6>
                                                <p>{data?.ctc_per_annum}</p>
                                            </div>
                                            <div className="col-sm-4 manpwr_data_colm">
                                                <h6>CTC Proposed (Monthly)</h6>
                                                <p>{data?.ctc_per_month}</p>
                                            </div>
                                            <div className="col-sm-4 manpwr_data_colm">
                                                <h6>Grade</h6>
                                                <p>{data?.grade}</p>
                                            </div>
                                            <div className="col-sm-4 manpwr_data_colm">
                                                <h6>Minimum Experience</h6>
                                                <p>{data?.minimum_experience}</p>
                                            </div>
                                            <div className="col-sm-4 manpwr_data_colm">
                                                <h6>Maximum Experience</h6>
                                                <p>{data?.maximum_experience}</p>
                                            </div>
                                            <div className="col-sm-4 manpwr_data_colm">
                                                <h6>No. of Vacancies</h6>
                                                <p>{data?.no_of_vacancy}</p>
                                            </div>
                                            <div className="col-sm-4 manpwr_data_colm">
                                                <h6>Place of Posting</h6>
                                                <p>{data?.place_of_posting?.map((item) => {
                                                    return `${item.location_name} , ${item.state_name}`
                                                }).join(', ')}</p>
                                            </div>
                                            <div className="col-sm-4 manpwr_data_colm">
                                                <h6>Reporting Structure</h6>
                                                <p>{data?.reporting_structure}</p>
                                            </div>
                                            <div className="col-sm-4 manpwr_data_colm">
                                                <h6>Time frame to fill the vacancy</h6>
                                                <p>{data?.vacancy_frame}</p>
                                            </div>
                                            <div className="col-sm-4 manpwr_data_colm">
                                                <h6>Upload Manpower Requisition Document</h6>
                                                <p>{data?.requisition_form}</p>
                                            </div>
                                            <div className="col-sm-12 manpwr_data_colm">
                                                <h6>Job Description</h6>
                                                <p dangerouslySetInnerHTML={{ __html: data?.job_description }} />

                                            </div>
                                            <div className="col-sm-12 manpwr_data_colm">
                                                <h6>Qualification</h6>
                                                <p dangerouslySetInnerHTML={{ __html: data?.qualification }} />
                                            </div>
                                            <div className="col-sm-12 manpwr_data_colm">
                                                <h6>Skills</h6>
                                                <p dangerouslySetInnerHTML={{ __html: data?.skills }} />
                                            </div>
                                        </div>
                                        {
                                            data?.activity_data?.some(item => item.designation === utm && item.status === "Approved")
                                                ? null
                                                :
                                                <div className="statusform">
                                                    <div className="fieldwrp">
                                                        <Form.Label>
                                                            Status
                                                        </Form.Label>
                                                        <FormControl>
                                                            <RadioGroup
                                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                                name="controlled-radio-buttons-group"
                                                                value={value}
                                                                onChange={handleChange}
                                                                className="d-flex justify-content-start gap-4 flex-row"
                                                            >
                                                                <FormControlLabel
                                                                    value="Approved"
                                                                    control={<Radio />}
                                                                    label="Approve"
                                                                />
                                                                <FormControlLabel
                                                                    value="Rejected"
                                                                    control={<Radio />}
                                                                    label="Reject"
                                                                />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </div>
                                                    <div className="fieldwrp">
                                                        <Form.Label>
                                                            Comment
                                                        </Form.Label>
                                                        <FormControl className="w-100">
                                                            <textarea className="form-control" value={text} onChange={(e) => setText(e.target.value)} placeholder="Enter Description" rows={3}></textarea>
                                                        </FormControl>
                                                    </div>
                                                    <button type="button" onClick={handleSubmitFeedBack} class="mt-4 formbtn btn btn-primary">Submit</button>
                                                </div>
                                        }
                                    </div>
                            }
                    </div>
                </div>
            </div>
        </>
    )

}


export default MprFormValidation